export default [
  {
    name: "Document Delivery",
    activePath: "/support/package",
    icon: { name: "ph:package", class: "w-5 h-5" },
    children: [
      {
        name: "History",
        to: "/support/package",
        icon: { name: "carbon:package", class: "w-4 h-4" },
      },
      {
        name: "Send Document",
        to: "/support/package/send-document",
        icon: { name: "lucide:package-plus", class: "w-4 h-4" },
      },
    ],
  },
]
