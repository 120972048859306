export default [
  {
    name: "Monitoring",
    icon: { name: "heroicons-outline:computer-desktop", class: "w-5 h-5" },
    activePath: "/monitoring",
    permissions: ["View Monitoring Project", "View Monitoring Consultation"],
    children: [
      {
        name: "Project",
        to: "/monitoring/project",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Monitoring Project"],
      },
      {
        name: "Consultation",
        to: "/monitoring/consultation",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Monitoring Consultation"],
      },
    ],
  },
]
