export default [
  {
    name: "Master",
    icon: { name: "heroicons-outline:circle-stack", class: "w-5 h-5" },
    activePath: "/master",
    roles: ["Super Admin"],
    children: [
      {
        name: "Bank",
        to: "/admin/master/bank",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        roles: ["Super Admin"],
      },
      {
        name: "Country",
        to: "/admin/master/country",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        roles: ["Super Admin"],
      },
      {
        name: "Province",
        to: "/admin/master/province",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        roles: ["Super Admin"],
      },
      {
        name: "City",
        to: "/admin/master/city",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        roles: ["Super Admin"],
      },
      // {
      //   name: "client-category",
      //   to: "/admin/master/client-category",
      //   name: "Client Category",
      //   permissions: ["View Client Category"],
      // },
      {
        name: "Questioner",
        to: "/admin/master/questioner",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Questioner"],
      },
      {
        name: "Currency",
        to: "/admin/master/currency",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        roles: ["Super Admin"],
      },
      // {
      //   name: "estimate-cost",
      //   to: "/admin/master/estimate-cost",
      //   name: "Estimate Cost",
      //   permissions: ["View Estimate Cost"],
      // },
      // {
      //   name: "funding-source",
      //   to: "/admin/master/funding-source",
      //   name: "Funding Source",
      //   permissions: ["View Funding Source"],
      // },
      // {
      //   name: "income-type",
      //   to: "/admin/master/income-type",
      //   name: "Income Type",
      //   permissions: ["View Income Type"],
      // },
      // {
      //   name: "number-employee",
      //   to: "/admin/master/number-employee",
      //   name: "Number Employee",
      //   permissions: ["View Number Employee"],
      // },
      // {
      //   name: "ptkp",
      //   to: "/admin/master/ptkp",
      //   name: "PTKP",
      //   permissions: ["View PTKP"],
      // },
      // {
      //   name: "profession",
      //   to: "/admin/master/profession",
      //   name: "Profession",
      //   permissions: ["View Profession"],
      // },
      // {
      //   name: "project-status",
      //   to: "/admin/master/project-status",
      //   name: "Project Status",
      //   permissions: ["View Project Status"],
      // },

      // {
      //   name: "rate-pph21",
      //   to: "/admin/master/rate-pph21",
      //   name: "Rate PPh 21",
      //   permissions: ["View PPh 21"],
      // },
      // {
      //   name: "scope-of-work",
      //   to: "/admin/master/scope-of-work",
      //   name: "Scope Of Work",
      //   permissions: ["View Scope Of Work"],
      // },
      // {
      //   name: "source-collection",
      //   to: "/admin/master/source-collection",
      //   name: "Source Collection",
      //   permissions: ["View Source Collection"],
      // },
      // {
      //   name: "transaction-category",
      //   to: "/admin/master/transaction-category",
      //   name: "Transaction Category",
      //   permissions: ["View Transaction Category"],
      // },
      // {
      //   name: "turnover-category",
      //   to: "/admin/master/turnover-category",
      //   name: "Turnover Category",
      //   permissions: ["View Turnover Category"],
      // },
    ],
  },
]
