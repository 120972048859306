export default [
  {
    name: "Support",
    icon: {
      name: "heroicons-outline:chat-bubble-left-right",
      class: "w-5 h-5",
    },
    activePath: "/support",
    children: [
      {
        name: "Helpdesk/Ticket",
        to: "/support/ticket",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Knowledge Base",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        to: "/support/knowledge-base",
      },
    ],
  },
]
