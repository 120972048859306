export default [
  {
    name: "Human Resource",
    activePath: "/human-resource",
    icon: { name: "heroicons-outline:user-group", class: "w-5 h-5" },
    permissions: ["Human Resource"],
    children: [
      {
        name: "Dashboard",
        to: "/human-resource/dashboard",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Dashboard Human Resource"],
      },
      {
        name: "Careers",
        to: "/human-resource/career",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Career"],
      },
      {
        name: "Employee",
        to: "/human-resource/employee",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Employee"],
      },
      {
        name: "Employee Status",
        to: "/human-resource/employee-status",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Employee Status"],
      },
      {
        name: "Department",
        to: "/human-resource/department",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Department"],
      },
      {
        name: "Position",
        to: "/human-resource/position",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Position"],
      },
      {
        name: "Make Announcements",
        to: "/human-resource/announcements",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Announcement"],
      },
      {
        name: "Holidays",
        to: "/human-resource/holidays",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Holiday"],
      },
      {
        name: "Organizational Chart",
        to: "/human-resource/organizational-chart",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "360 KPI (Indicator)",
        to: "/human-resource/indicator",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Performance Indicator"],
      },
      {
        name: "Setting KPI (indicator)",
        to: "/human-resource/setting-indicator",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Setting Performance Indicator"],
      },
      {
        name: "Overtime",
        to: "/human-resource/overtime",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Overtime"],
      },
      // {
      //   name: "KPA (Appraisal)",
      //   to: "/human-resource/appraisal",
      //   name: "human-resource-appraisal",
      //   permissions: ["View Appraisal"],
      // },
      {
        name: "Competencies",
        to: "/human-resource/competency",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Competency"],
      },
      // {
      //   name: "Track Goals (OKRs)",
      //   to: "/human-resource/track-goals",
      //   name: "human-resource-track-goals",
      //   permissions: ["View Track Goals"],
      // },
      {
        name: "Goal Type",
        to: "/human-resource/goal-type",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Goal Type"],
      },
      // {
      //   name: "Goal Calendar",
      //   to: "/human-resource/goal-calendar",
      //   name: "human-resource-goal-calendar",
      //   permissions: ["View Goal Calendar"],
      // },
    ],
  },
]
