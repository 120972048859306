import dashboard from "~/navigation/dashboard"
import master from "~/navigation/admin/master"
import accessControl from "~/navigation/accessControl"
import project from "~/navigation/project"
import user from "~/navigation/user"
import report from "~/navigation/report"
import marketing from "~/navigation/marketing"
import support from "~/navigation/support"
import finance from "~/navigation/finance"
import humanResources from "~/navigation/humanResource"
import entities from "~/navigation/admin/entities"
import company from "~/navigation/company"
import monitoring from "~/navigation/monitoring"
import application from "~/navigation/application"
import website from "~/navigation/website"
import sleekflow from "~/navigation/sleekflow"
import asset from "~/navigation/asset"
import inadata from "~/navigation/inadata"
import documentDelivery from "~/navigation/documentDelivery"
import inapintar from "~/navigation/inapintar"
import taxCalculation from "./taxCalculation"

export const navItems = [
  ...dashboard,
  ...inapintar,
  ...inadata,
  ...accessControl,
  ...company,
  ...humanResources,
  ...marketing,
  ...sleekflow,
  ...documentDelivery,
  ...project,
  ...finance,
  ...asset,
  ...application,
  ...report,
  ...monitoring,
  ...support,
  ...website,
  ...taxCalculation,
]
