export default [
  {
    name: "Project",
    icon: { name: "heroicons-outline:clipboard-document", class: "w-5 h-5" },
    activePath: "project",
    exact: false,
    children: [
      {
        name: "Dashboard",
        to: "/project/dashboard",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Create Project",
        to: "/project/create",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["Create Project"],
      },
      {
        name: "Quotation",
        to: "/project/listing/quotation",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Ongoing",
        to: "/project/listing/ongoing",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Finished/Cancelled",
        to: "/project/listing/finished",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
    ],
  },
]
