export default [
  {
    name: "Sleekflow",
    icon: { name: "heroicons-outline:shopping-bag", class: "w-5 h-5" },
    activePath: "sleekflow",
    children: [
      {
        name: "Contacts",
        to: "/sleekflow/contact-list",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
    ],
  },
]
