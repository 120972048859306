export default [
  {
    name: "Inadata",
    activePath: "/inadata",
    icon: { name: "mdi:faq", class: "w-5 h-5" },
    children: [
      {
        name: "Glosarium",
        to: "/inadata/glosarium",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Peraturan Pajak",
        to: "/inadata/peraturan-pajak",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
    ],
  },
]
