export default [
  {
    name: "Dashboards",
    icon: { name: "heroicons-outline:chart-bar-square", class: "w-5 h-5" },
    to: "/partner",
    exact: true,
  },
  {
    name: "Client",
    icon: { name: "heroicons-outline:users", class: "w-4 h-4" },
    exact: false,
    to: "/partner/client",
  },
  {
    name: "Project",
    icon: { name: "heroicons-outline:clipboard-document", class: "w-4 h-4" },
    exact: false,
    to: "/partner/project",
  },
  {
    name: "Invoice",
    icon: {
      name: "heroicons-outline:clipboard-document-list",
      class: "w-4 h-4",
    },
    to: "/partner/invoice",
    exact: false,
  },
]
