export default [
  {
    name: "Finance",
    activePath: "/finance",
    icon: {
      name: "heroicons-outline:clipboard-document-list",
      class: "w-5 h-5",
    },
    permissions: ["Finance"],
    children: [
      {
        name: "Dashboard",
        to: "/finance",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["Create Invoice"],
      },
      {
        name: "Promo/Referral",
        to: "/finance/promo",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Promo"],
      },
      {
        name: "Request Invoice",
        to: "/finance/request-invoice",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Request Invoice"],
      },
      {
        name: "Invoice",
        to: "/finance/invoice",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Invoice"],
      },
      {
        name: "Pembayaran Invoice",
        to: "/finance/payments",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Invoice"],
      },
      {
        name: "Invoice Counter",
        to: "/finance/invoice-counter",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        roles: ["Super Admin"],
      },
      {
        name: "Reimbursement",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        to: "/finance/reimbursement",
        permissions: ["Create Invoice"],
      },
      {
        name: "Pengajuan Dana",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        to: "/finance/fund-application",
        permissions: ["Create Invoice"],
      },
      // {
      //   name: "Cash Advance",
      //   icon: { name: "la:dot-circle", class: "w-4 h-4" },
      //   to: "/finance/cash-advance",
      //   permissions: ["Create Invoice"],
      // },
    ],
  },
]
