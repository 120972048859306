export default [
  {
    name: "Marketing",
    activePath: "/marketing",
    icon: { name: "heroicons-outline:shopping-bag", class: "w-5 h-5" },
    permissions: ["Marketing"],
    children: [
      {
        name: "Dashboard",
        to: "/marketing/dashboard",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Client"],
      },
      {
        name: "Lead",
        to: "/marketing/lead",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Lead"],
      },
      {
        name: "Potential Client",
        to: "/marketing/potential",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Potential Client"],
      },
      {
        name: "Client",
        to: "/marketing/client",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Client"],
      },
      {
        name: "Sales Partner",
        to: "/marketing/sales",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Sales Partner"],
      },
      {
        name: "Speaker/Host",
        to: "/marketing/speaker-host",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Speaker"],
      },
      {
        name: "Event(Seminar/Webinar)",
        to: "/marketing/seminar",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Seminar"],
      },
      {
        name: "Template Questioner",
        to: "/marketing/template-questioner",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Template Questioner"],
      },
    ],
  },
]
