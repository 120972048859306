export default [
  {
    name: "Asset Management",
    icon: { name: "carbon:task-asset-view", class: "w-5 h-5" },
    permissions: ["Asset Management"],
    activePath: "/asset-management",
    children: [
      {
        name: "Vendor",
        to: "/asset-management/vendor",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Vendor"],
      },
      {
        name: "Asset Type",
        to: "/asset-management/asset-type",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Asset Type"],
      },
      {
        name: "Asset Location",
        to: "/asset-management/asset-location",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Asset Location"],
      },
      {
        name: "Master Asset",
        to: "/asset-management/asset",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Master Asset"],
      },
    ],
  },
]
