export default [
  {
    icon: { name: "heroicons-outline:chart-bar-square", class: "w-5 h-5" },
    to: "/",
    name: "Dashboard",
  },

  {
    icon: { name: "flat-color-icons:overtime", class: "w-5 h-5" },
    to: "/overtime-letter",
    name: "Overtime Letter",
    permissions: ["Can Approve Overtime"],
  },
  {
    icon: { name: "tabler:list-check", class: "w-5 h-5" },
    to: "/direct-assignment",
    name: "Direct Assignment",
  },
]
