export default [
  {
    name: "Website",
    icon: { name: "heroicons-outline:globe-alt", class: "w-5 h-5" },
    activePath: "/website",
    permissions: ["Marketing"],
    children: [
      {
        name: "Post",
        to: "/website/post",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Expert",
        to: "/website/expert",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Service",
        to: "/website/service",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Page SEO",
        to: "/website/page-seo",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
    ],
  },
]
