import entities from "~/navigation/admin/entities"
import master from "~/navigation/admin/master"
import user from "~/navigation/user"

export const navAdminItems = [
  {
    icon: { name: "heroicons-outline:chart-bar-square", class: "w-5 h-5" },
    to: "/admin",
    name: "Dashboard",
  },
  ...entities,
  ...master,
  ...user,
]
