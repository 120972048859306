export default [
  {
    name: "Report",
    icon: { name: "heroicons-outline:document-check", class: "w-5 h-5" },
    activePath: "/report",
    children: [
      {
        name: "Monthly Report",
        to: "/report/monthly",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Report Monthly"],
      },
      {
        name: "Timesheet",
        to: "/report/timesheet",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Potential Invoice",
        to: "/report/potential-invoice",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Potential Invoice"],
      },
      {
        name: "Timer Consultant",
        to: "/report/timer-consultant",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Monitoring Timer"],
      },
      {
        name: "Project Per Status",
        to: "/report/project-per-status",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Monitoring Project Perstatus"],
      },
      {
        name: "Progress Project",
        to: "/report/progress-project",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Monitoring Project Progress"],
      },
      {
        name: "Project Marginal Margin",
        to: "/report/project-marginal-margin",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Project Marginal Margin"],
      },
      {
        name: "Timer Consultant/Project",
        to: "/report/timer-consultant-per-project",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Monitoring Project Perstatus"],
      },
    ],
  },
]
