export default [
  {
    name: "Access Control",
    activePath: "/access-control",
    icon: { name: "heroicons-outline:lock-closed", class: "w-5 h-5" },
    permissions: ["ACL"],
    children: [
      {
        name: "Permission",
        to: "/access-control/permission",
        icon: { name: "heroicons-outline:lock-closed", class: "w-4 h-4" },
        permissions: ["View Permission"],
      },
      {
        name: "Role",
        to: "/access-control/role",
        icon: { name: "heroicons-outline:lock-closed", class: "w-4 h-4" },
        permissions: ["View Roles"],
      },
    ],
  },
]
